/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Dialog,
  DialogSurface,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { CloseIcon, DownloadCurvedIcon } from "components/icons";
import React from "react";
import toast from "react-hot-toast";

export const Lightbox = ({
  url,
  filename,
  open,
  onClose,
  fileType,
}: {
  url: string;
  filename: string;
  open: boolean;
  onClose: () => void;
  fileType: string;
}) => {
  const style = useStyle();
  return (
    <div className={`${style.lightbox} ${open ? "open" : ""}`}>
      <div className={style.lightbox_header}>
        <a
          href={url}
          download={filename}
          onClick={() => {
            toast.custom(
              (t) => <div className={style.toast}>Downloading...</div>,
              {
                duration: 400,
                position: "top-right",
                className: style.toast,
                style: {
                  top: "100px",
                },
              }
            );
          }}
        >
          <DownloadCurvedIcon style={{ cursor: "pointer", color: "white" }} />
        </a>

        <div onClick={onClose}>
          <CloseIcon style={{ cursor: "pointer", color: "white" }} />
        </div>
      </div>
      <div className={style.lightbox_body}>
        <DisplayFile fileType={fileType} url={url} />
        {/* <img
            src={url}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          /> */}
      </div>
    </div>
  );
};

const DisplayFile = ({ fileType, url }: { fileType: string; url: string }) => {
  switch (fileType) {
    case "application/pdf":
      return (
        <iframe
          src={url}
          width="100%"
          height="600px"
          title="PDF Viewer"
          style={{ border: "none" }}
        >
          This browser does not support PDFs. Please download the PDF to view
          it: <a href={url}>Download PDF</a>.
        </iframe>
      );
    case "image/png":
    case "image/jpg":
    case "image/jpeg":
      return (
        <img
          src={url}
          alt="uploaded image"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      );
  }
  return <></>;
};

const useStyle = makeStyles({
  lightbox: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    opacity: 0,
    ...shorthands.transition("opacity", "0.5s", "ease-in-out"),
    "&.open": {
      opacity: 1,
    },
  },
  lightbox_header: {
    height: "42px",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("24px"),
    ...shorthands.padding("16px"),
  },
  lightbox_body: {
    height: "calc(100vh - 42px)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // background: "#000",
    backdropFilter: "blur(4px)",
    ...shorthands.padding("24px"),
  },
  toast: {
    position: "absolute",
    top: "40px",
    right: "16px",
    backgroundColor: "#000",
    color: "#fff",
    ...shorthands.padding("8px", "16px"),
    ...shorthands.borderRadius("50px"),
    ...shorthands.transition("opacity", "0.5s", "ease-in-out"),
    opacity: 1,
    "&.hidden": {
      opacity: 0,
    },
  },
});
