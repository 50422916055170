import { axiosClient } from "Utils";
import axios from "axios";
import { PostLoginInput } from "shared";

const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;
export const preLogin = async (email: string) => {
  try {
    let response = await axios.post(`${authBaseUrl}/sso/prelogin`, {
      email: email,
    });
    const resData = response.data;
    if (resData?.success && resData?.statusCode === 200 && resData?.data) {
      return resData.data;
    }
  } catch (e: any) {
    throw new Error(e.message || "Unable to prelogin");
  }
};

export const postLogin = async ({
  code,
  state,
  redirectUrl,
}: PostLoginInput) => {
  try {
    let response = await axios.post(`${authBaseUrl}/sso/postlogin`, {
      state: state,
      code: code,
      redirectUri: redirectUrl,
    });
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200 && resData?.data) {
      return resData.data;
    }
    throw new Error(resData?.message || "Unable to login");
  } catch (e: any) {
    throw new Error(
      e?.response?.data?.message || e?.message || "Unable to login"
    );
  }
};

export const getApiToken = async (token: string) => {
  try {
    let response = await axios.post(
      `${authBaseUrl}/v1/machine/token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200 && resData?.data) {
      return resData.data;
    }
    throw new Error(resData?.message || "Unable to login");
  } catch (e: any) {
    throw new Error(
      e?.response?.data?.message || e?.message || "Unable to login"
    );
  }
};

export const login = async ({
  email = "",
  password = "",
}: {
  email: string;
  password: string;
}) => {
  try {
    let response = await axios.post(`${authBaseUrl}/agent/login`, {
      email: email,
      password: password,
    });
    const resData = response.data;
    if (resData?.success && resData?.statusCode === 200 && resData?.data) {
      return resData.data;
    }
    throw resData;
  } catch (e: any) {
    throw new Error(
      e?.response?.data?.message || e?.message || "Unable to login"
    );
  }
};

export const resetPassword = async (email: string) => {
  try {
    let response = await axios.post(
      `${authBaseUrl}/reset/user/password/token`,
      {
        email: email,
      }
    );
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData.data;
    }
    throw new Error(resData?.message || "Unable to reset password");
  } catch (e: any) {
    throw new Error(
      e?.response?.data?.message || e?.message || "Unable to reset password"
    );
  }
};

export const logout = async (token: string) => {
  try {
    let response = await axios.post(
      `${authBaseUrl}/v2/agent/logout`,
      {},
      {
        headers: {
          "X-API-KEY": token,
        },
      }
    );
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData.data;
    }
    throw new Error(resData?.message || "Unable to logout");
  } catch (e: any) {
    if (e?.response?.status >= 400) {
      throw new Error("UNAUTHERIZED");
    }

    throw new Error(e?.message || "Unable to logout");
  }
};
