import ReactQuill, { Quill } from "react-quill";
import { v4 } from "uuid";
import { DeltaStatic, Sources } from "quill";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import "react-quill/dist/quill.snow.css";
import sanitizeHtml from "sanitize-html";
import { getFileExtensionFromMimeType, isUrl } from "Utils";
import axios from "axios";
import { uploadChatAttachments } from "controller/api";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

// Import the default image format
const ImageFormat = Quill.import("formats/image");

// Extend the image blot to support custom attributes
class CustomImage extends ImageFormat {
  static create(value: any) {
    // If value is an object, extract the src; otherwise, assume value is the URL
    const node = super.create(value.src || value);
    // Set the alt attribute (defaulting to an empty string if not provided)
    node.setAttribute("alt", value.alt || "");
    // Set the style attribute (defaulting to empty if not provided)
    node.setAttribute(
      "style",
      "max-width: 180px; max-height: 180px; object-fit: contain; object-position: center center;"
    );
    return node;
  }

  static value(node: any) {
    // Return an object with all our attributes
    return {
      src: node.getAttribute("src"),
      alt: node.getAttribute("alt"),
      style: node.getAttribute("style"),
    };
  }
}

// Update the blot name and tag to match the defaults
CustomImage.blotName = "image";
CustomImage.tagName = "img";

// Register your custom image blot with Quill
Quill.register(CustomImage);

// Create a custom blot to display a loader
const BlockEmbed = Quill.import("blots/block/embed");

class ImageLoaderBlot extends BlockEmbed {
  static blotName = "imageLoader";
  static tagName = "div";
  static className = "image-loader";

  static create(value: {
    id: string;
    height: string;
    width: string;
    style: any;
  }) {
    const node = super.create(value);
    const child = document.createElement("div");

    child.setAttribute("class", "loader");
    // child.setAttribute("style", value.style);
    child.style.backgroundColor = "#F1F2FE";
    child.style.height = "180px";
    child.style.width = "180px";
    child.style.borderRadius = "8px";
    child.style.display = "flex-row";
    child.style.justifyContent = "center";
    child.style.alignItems = "center";
    const spinner = document.createElement("div");
    spinner.className = "spinner";
    const spinnerLine = document.createElement("div");
    spinnerLine.className = "spinner-line";
    spinnerLine.style.animation = "spin 1s linear infinite";
    spinner.appendChild(spinnerLine);
    child.appendChild(spinner);
    node.appendChild(child);
    node.setAttribute("data-loader-id", value.id);
    // // Create loader HTML with a spinner element
    // const loaderHTML = renderToStaticMarkup(<div className="loader"></div>);
    // node.innerHTML = loaderHTML;
    return node;
  }

  static value(node: HTMLElement) {
    return {
      id: node.getAttribute("data-loader-id") || "",
      height: node.getAttribute("height"),
      width: node.getAttribute("width"),
      style: node.getAttribute("style"),
    };
  }
}

Quill.register(ImageLoaderBlot);

// Get Delta for constructing editor changes
const Delta = Quill.import("delta");

interface RichTextEditorProps {
  serviceId: string;
  serviceType: string;
  isDisabled: boolean;
  onChange: (value: string) => void;
  onSend: () => void;
  value: any;
  ref: any;
  toggleUploadingStatus: (value: boolean) => void;
}

export const ChatMessageInput = forwardRef(
  (
    {
      serviceId,
      serviceType,
      isDisabled,

      onSend,
      onChange,
      value,
      toggleUploadingStatus,
    }: RichTextEditorProps,
    ref
  ) => {
    const style = useStyles();

    const quilRef = useRef<ReactQuill>(null);
    const [attachmentArray, setAttachmentArray] = useState([]);
    const [totalImgs, setTotalImgs] = useState(0);

    useEffect(() => {
      if (totalImgs === 0) {
        toggleUploadingStatus(false);
      }
    }, [totalImgs]);

    // INFO: when pasting large data with images quill was not clearing on setValue.. so clearing manually
    useImperativeHandle(ref, () => ({
      clear: () => {
        if (quilRef) {
          // @ts-ignore
          const editor = quilRef.current.getEditor();
          // Deletes all content from the beginning (index 0) to the end
          // editor.deleteText(0, editor.getLength());

          editor.setText("");
        }
      },
    }));

    const handleImagePaste = (e: any) => {
      const quill =
        quilRef && quilRef.current ? quilRef?.current.getEditor() : null;
      if (quill) {
        const clipboardItems = e.clipboardData.items;
        for (let i = 0; i < clipboardItems.length; i++) {
          const item = clipboardItems[i];
          if (
            item.type === "image" ||
            item.type === "image/png" ||
            item.type === "image/jpeg" ||
            item.type === "image/jpg" ||
            item.type.indexOf("image") !== -1
          ) {
            setTotalImgs((prev) => prev + 1);
            const file = item.getAsFile();
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64Image = reader.result;
              const range = quill.getSelection(); // Get current selection range
              if (range) {
                const id = v4();
                uploadImage({
                  // @ts-ignore
                  src: base64Image,
                  id,
                  quill,
                  style: {},
                  alt: "",
                });

                const delta = new Delta().insert({
                  imageLoader: {
                    id,
                    height: "180px",
                    width: "180px",
                    style: {},
                  },
                });
                quill.updateContents(delta, "user");
              }
            };
            reader.readAsDataURL(file);
            e.preventDefault();
          }
        }
      }
    };

    const customImageMatchers = [
      [
        "img",
        (node: any, delta: any) => {
          setTotalImgs((prev) => prev + 1);
          if (quilRef.current) {
            const quill = quilRef.current.getEditor();
            const imgSrc = node.getAttribute("src");
            const style = node.getAttribute("style");
            const height = node.getAttribute("height");
            const width = node.getAttribute("width");
            const alt = node.getAttribute("alt");
            const id = v4();
            uploadImage({ src: imgSrc, id, quill, style, alt });
            return new Delta().insert({
              imageLoader: { id, height, width, style },
            });
          }
        },
      ],
      // [
      //   "img",
      //   (node: any, delta: any) => {
      //     console.log("running");
      //     const imgSrc = node.getAttribute("src");
      //     console.log("Pasted image src:", imgSrc);
      //     console.log(delta);
      //     delta.ops = delta.ops.map((op: any) => {
      //       const originalAttributes = {
      //         ...(op && op.attributes ? op.attributes : {}),
      //       };

      //       if (op.insert) {
      //       }
      //       if (op.attributes) {
      //         op.attributes = {
      //           ...originalAttributes,
      //           alt: "transformed_img",
      //           height: "auto",
      //           width: "100%",
      //         };
      //       }
      //       return {
      //         insert: imgSrc || "image",
      //         attributes: {
      //           link: imgSrc,
      //         },
      //       };
      //     });
      //     return delta;
      //   },
      // ],
    ];

    const dataURLtoBlob = (dataurl: string): Blob => {
      const arr = dataurl.split(",");
      const mimeMatch = arr[0].match(/:(.*?);/);
      if (!mimeMatch) {
        throw new Error("Invalid data URL");
      }
      const mime = mimeMatch[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    };

    const uploadImage = async ({
      src,
      id,
      quill,
      style,
      alt,
    }: {
      src: string;
      id: string;
      quill: any;
      style: any;
      alt: string;
    }) => {
      try {
        toggleUploadingStatus(true);
        let blob;
        if (isUrl(src)) {
          const externalData = await axios.get(src, {
            responseType: "blob",
          });
          if (externalData.status === 200) {
            blob = externalData.data;
          }
        } else {
          blob = dataURLtoBlob(src);
        }

        const formData = new FormData();
        // @ts-ignore
        formData.append(
          "files",
          blob,
          `${serviceId}_ticket_${id}.${getFileExtensionFromMimeType(blob.type)}`
        );
        uploadChatAttachments({
          formData,
          serviceId: serviceId,
          serviceType,
        }).subscribe({
          next: ({ response, status, percentage, controller }: any) => {
            if (status === "COMPLETE") {
              if (response?.success && response?.statusCode === 200) {
                setTotalImgs((prev) => prev - 1);
                const imgData =
                  response && response.data ? response.data[0] : {};
                setAttachmentArray((prev) => {
                  const newArr = [...prev];
                  // @ts-ignore
                  newArr.push(imgData);
                  return newArr;
                });
                replaceLoaderWithImage({
                  quill,
                  id,
                  src: imgData.url,
                  style,
                  alt,
                });
              }
            }
          },
        });
        // // Replace '/upload' with your actual endpoint
        // const response = await axios.post("/upload", formData, {
        //   headers: { "Content-Type": "multipart/form-data" },
        // });

        // // Assume the response contains the URL of the uploaded image
        // const imageUrl = response.data.url;
        // replaceLoaderWithImage(quill, id, imageUrl);
      } catch (error) {
        console.error("Upload failed", error);
        // Optionally, remove the loader or insert an error placeholder
      }
    };

    const replaceLoaderWithImage = ({
      quill,
      id,
      src,
      style,
      alt,
    }: {
      src: string;
      id: string;
      quill: any;
      style: any;
      alt: string;
    }) => {
      const editor = quill.root; // the editor's DOM node
      const loaderDiv = editor.querySelector(`div[data-loader-id="${id}"]`);
      if (loaderDiv) {
        const blot = Quill.find(loaderDiv);
        const index = quill.getIndex(blot);
        // Remove the loader blot and insert the actual image
        quill.deleteText(index, 1);
        quill.insertEmbed(index, "image", {
          src,
          alt,
          style,
        });
      }
    };
    // Custom keyboard module: intercept "Enter" key (without shift) to send the message.
    const modules = useMemo(
      () => ({
        toolbar: false,
        keyboard: {
          bindings: {
            sendOnEnter: {
              key: 13, // Enter key
              handler: (range: any, context: { shiftKey: boolean }) => {
                // If Shift is not pressed, send the message.
                if (!context.shiftKey) {
                  // Return false to prevent the default newline behavior.
                  return false;
                }
                // If Shift is pressed, allow the default behavior (insert newline).
                return true;
              },
            },
          },
        },
        clipboard: {
          matchVisuals: false,
          matchers: customImageMatchers,
        },
      }),
      []
    );

    const handleKeyDown = (e: any) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        onSend();
      }
    };

    const onChangeHandler = (
      value: string,
      delta: DeltaStatic,
      source: Sources,
      editor: ReactQuill.UnprivilegedEditor
    ) => {
      const sanitizedValue = sanitizeHtml(value, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img", "br"]),
        selfClosing: sanitizeHtml.defaults.selfClosing.concat(["img", "br"]),
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          img: ["src", "alt", "title", "width", "height", "style"],
        },
        allowedSchemesByTag: {
          img: ["http", "https", "data"],
        },
        transformTags: {
          img: (tagName, attribs) => {
            // Remove width and height styles if present.
            if (attribs.style) {
              // Split styles by semicolon, filter out any rules for width or height.
              const filteredStyles = attribs.style
                .split(";")
                .map((rule) => rule.trim())
                .filter(
                  (rule) =>
                    rule &&
                    !rule.startsWith("width:") &&
                    !rule.startsWith("height:")
                );
              attribs.style = filteredStyles.join("; ");
            }
            // Add/override attributes.
            attribs.width = "100%";
            attribs.height = "auto";
            attribs.alt = "transformed_img";
            // Append aspect ratio styling.
            const aspectRatioStyle = "aspect-ratio: 1/1";
            if (attribs.style) {
              attribs.style = attribs.style + "; " + aspectRatioStyle;
            } else {
              attribs.style = aspectRatioStyle;
            }
            return {
              tagName,
              attribs,
            };
          },
        },
      });
      onChange(value);
    };
    return (
      <div className={style.container_style}>
        <div onKeyDownCapture={handleKeyDown} className={style.root}>
          <div className={style.quill_editor_div} id="quill-editor">
            <ReactQuill
              ref={quilRef}
              style={{
                height: "100%",
                overflow: "scroll",
                // border: props.error ? "1px solid #DA1E28" : "1px solid #d9d9d9",
                whiteSpace: "pre-line",
                backgroundColor: "transparent",
              }}
              theme="snow"
              value={value}
              onChange={onChangeHandler}
              placeholder={`Type a new message`}
              modules={modules}
              readOnly={isDisabled}
              // {...props}
            />
          </div>
        </div>
      </div>
    );
  }
);

const useStyles = makeStyles({
  container_style: {
    alignSelf: "center",
    width: "100%",
    backgroundColor: "transparent",
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightRegular,
    color: tokens.colorNeutralForeground2,
    ...shorthands.overflow("auto"),
    minHeight: "32px",
    maxHeight: "200px",
  },
  quill_editor_div: {
    width: "100%",
    height: "100%",
  },

  root: {
    minHeight: "32px",
    width: "100%",
    display: "flex",
    flexDirection: "column",

    // Quill editor styles
    "& .ql-editor": {
      minHeight: "32px",
      maxHeight: "200px",
      width: "100%",
      height: "auto",

      overflowY: "auto",
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
      ...shorthands.padding("8px"),
      ...shorthands.flex("1 1 auto"),
    },

    "& .ql-toolbar.ql-snow": {
      ...shorthands.padding("0"),
      ...shorthands.margin("0"),
      ...shorthands.borderTop("1px", "solid", "#E3E3E3"),
      ...shorthands.borderBottom("1px", "solid", "#E3E3E3"),
      ...shorthands.borderLeft("none"),
      ...shorthands.borderRight("none"),
    },

    "& .ql-container": {
      ...shorthands.border("none"),
      minHeight: "0",
    },

    "& .ql-container.ql-snow": {
      backgroundColor: "transparent",

      height: "100%",
      display: "flex",
      ...shorthands.border("none"),
      minHeight: "32px",
      maxHeight: "200px",
      ...shorthands.overflow("scroll"),
    },

    "& .ql-editor > p": {
      color: tokens.colorNeutralForeground1,
    },

    "& .ql-editor.ql-blank::before": {
      paddingLeft: "0",
      marginLeft: "-6px",
    },

    "& .spinner": {
      position: "relative",
      width: "20px",
      height: "20px",
      ...shorthands.margin("20px", "auto"),
    },

    "& .spinnerLine": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      height: "2px",
      backgroundColor: "var(--colorBrandBackground)",
      ...shorthands.borderRadius("50%"),
    },

    "@keyframes spin": {
      from: {
        transform: "translate(-50%, -50%) rotate(0deg)",
      },
      to: {
        transform: "translate(-50%, -50%) rotate(360deg)",
      },
    },

    // other style

    "& .ql-editor::before": {
      content: "attr(placeholder)",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      fontStyle: "normal !important",
    },
  },
});
