import {
  Body1Stronger,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Option,
  Subtitle1,
  ToggleButton,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Box } from "components";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  CancelTicketModal,
  CreateTicketModal,
  FilterComponent,
  MemberComboBox,
  ProfileButton,
  TicketListViewer,
} from "./components";
import {
  useCloseBulkTickets,
  useGetAllTicketStatus,
  useGetAllTickets,
} from "controller/hook/useTicket";
import {
  TeamsFxContext,
  useGetAllMembers,
  useGetAllUsers,
  useGlobalStore,
  useMainStore,
} from "controller";
import {
  Grid20Filled,
  List20Regular,
  Search20Regular,
  Grid20Regular,
  Add20Regular,
} from "@fluentui/react-icons";
import { getFilterDateValue } from "Utils";
import toast from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import { DISABLED_STATUS } from "shared";
import { addMixpanelEvent } from "Utils/helpers/mixpanelLogger";
export const HomePage = () => {
  const style = useStyle();
  const { themeString } = useContext(TeamsFxContext);
  const { mutateAsync: closeBulkTicket } = useCloseBulkTickets();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCancelTicketModalOpen, setIsCancelTicketModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [ticketView, setTicketView] = useState<"list" | "grid">("grid");
  const userId = useGlobalStore((state) => state.userId);
  const customerId = useGlobalStore((state) => state.customerId);
  const isPrivilaged = useGlobalStore((state) => state.isPrivilaged);
  const isSuperManager = useGlobalStore((state) => state.isSuperManager);
  console.log("🚀 ~ HomePage ~ isSuperManager:", isSuperManager);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [selectedUserOptions, setSelectedUserOptions] = useState<string[]>([]);
  const [isScrollable, setIsScrollable] = useState(false);
  const [ticketType, setTicketType] = useState({
    label: "My tickets",
    value: "my_tickets",
  });

  const { data: users } = useGetAllUsers({
    customerId: customerId,
    isSuperManager: isSuperManager,
  });

  const { data: members } = useGetAllMembers({
    customerUserId: userId,
    isPrivilaged: isPrivilaged,
  });

  const filters = useMainStore((state) => state.homefilter);

  useEffect(() => {
    const parentContainer = document.getElementById("home_body_div");
    // let childList = document.getElementById("ticket_list");

    parentContainer?.addEventListener?.("scroll", () => {
      const isAtBottom =
        parentContainer.scrollHeight - parentContainer.scrollTop - 2 <=
        parentContainer.clientHeight;
      if (isAtBottom) {
        setIsScrollable(true);
        // childList.style.overflowY = "auto"; // Enable scrolling for child list
      } else {
        setIsScrollable(false);
        // childList.style.overflowY = "hidden"; // Disable scrolling for child list
      }
    });
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });

  const isMiniDevice = useMediaQuery({
    query: "(max-width: 380px)",
  });

  const { data: ticketStatus } = useGetAllTicketStatus(
    userId,
    ticketType.value as any
  );

  const { data: ticketData, isFetching: isTicketLoading } = useGetAllTickets({
    customerUserId: userId,
    searchQuery: searchValue,
    sortColumn: filters.sortBy,
    sortDirection: filters.sortDirection,
    filter: [
      ...(filters.priority.length > 0
        ? [
            {
              column: "tickets.priority",
              operator: "=",
              value: filters.priority,
            },
          ]
        : []),
      ...(filters.date && filters.date.length > 0
        ? [
            {
              column: "tickets.created_at",
              operator: ">",
              value: getFilterDateValue(filters.date),
            },
          ]
        : []),
      { column: "view_type", operator: "=", value: ticketType.value },
      {
        column: "selected_users",
        operator: "=",
        value: selectedUserOptions || [],
      },
    ],
  });

  useEffect(() => {
    try {
      setIsAllSelected(
        ticketData?.result.length > 0 &&
          selectedTickets.length ===
            ticketData?.result.filter(
              (item: any) => !DISABLED_STATUS.includes(item?.ticket_status)
            ).length
      );
    } catch (e) {
      setIsAllSelected(false);
    }
  }, [selectedTickets, ticketData?.result]);

  useEffect(() => {
    setIsAllSelected(false);
    setSelectedTickets([]);
    setSelectedUserOptions([]);
  }, [ticketType.value]);

  const closeTicketHandler = async () => {
    try {
      await closeBulkTicket({
        ticket_ids: selectedTickets.map((ticket: any) => ticket.mapping_id),
      });
      setSelectedTickets([]);
    } catch (e) {
      toast.error("Unable to close tickets at the moment");
    }
  };

  const membersList = useMemo(() => {
    if (ticketType.value === "team_tickets") {
      return Array.isArray(members) ? members : [];
    }
    return (Array.isArray(users?.result) ? users?.result : []).map(
      (user: any) => ({
        ...user,
        customer_user_id: user.mapping_id,
      })
    );
  }, [members, users, ticketType.value]);

  return (
    <div className={style.home_body}>
      {isCreateModalOpen && (
        <CreateTicketModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}

      <CancelTicketModal
        isMultiple={selectedTickets.length > 1}
        isOpen={isCancelTicketModalOpen}
        onClose={() => {
          setIsCancelTicketModalOpen(false);
        }}
        onSubmit={() => {
          closeTicketHandler();
          setIsCancelTicketModalOpen(false);
        }}
      />
      <div className={style.home_header}>
        <Subtitle1>Overview</Subtitle1>
        <div className={style.header_action}>
          {!isMobile && isPrivilaged && (
            <>
              <Dropdown
                appearance="filled-lighter"
                className={mergeClasses(style.dropdown, style.search_input)}
                placeholder="Select type"
                selectedOptions={[ticketType.value]}
                value={ticketType.label}
                onOptionSelect={(_, data: any) => {
                  setTicketType({
                    label: data.optionText ?? "",
                    value: data.selectedOptions[0] || "",
                  });
                  if (
                    isPrivilaged &&
                    data.selectedOptions[0] === "team_tickets"
                  ) {
                    addMixpanelEvent({
                      event: "team_ticket",
                    });
                  }
                }}
              >
                {(isSuperManager
                  ? superMangerTicketTypes
                  : privillagedUserTicketTypes
                ).map((option) => (
                  <Option
                    key={option.value}
                    value={option.value}
                    text={option.label}
                  >
                    {option.label}
                  </Option>
                ))}
              </Dropdown>
              {ticketType.value !== "my_tickets" && (
                <MemberComboBox
                  className={style.search_input}
                  members={membersList}
                  selectedUsers={selectedUserOptions}
                  onSubmit={(selectedusers) =>
                    setSelectedUserOptions(selectedusers)
                  }
                />
              )}
            </>
          )}

          <Button
            appearance="primary"
            size="medium"
            onClick={() => setIsCreateModalOpen(true)}
            icon={<Add20Regular />}
            iconPosition="after"
          >
            New Ticket
          </Button>
          {/* <Button appearance="transparent" icon={<Alert20Filled color={tokens.colorCompoundBrandBackground}/>}/> */}
          <ProfileButton />
        </div>
      </div>
      {isMobile && isPrivilaged && (
        <div className={style.ticket_type_box}>
          <>
            <div className={style.ticket_type_item}>
              <Dropdown
                appearance="filled-lighter"
                className={mergeClasses(style.selector, style.search_input)}
                placeholder="Select type"
                selectedOptions={[ticketType.value]}
                value={ticketType.label}
                onOptionSelect={(_, data: any) =>
                  setTicketType({
                    label: data.optionText ?? "",
                    value: data.selectedOptions[0] || "",
                  })
                }
              >
                {(isSuperManager
                  ? superMangerTicketTypes
                  : privillagedUserTicketTypes
                ).map((option) => (
                  <Option
                    key={option.value}
                    value={option.value}
                    text={option.label}
                  >
                    {option.label}
                  </Option>
                ))}
              </Dropdown>
            </div>
            <div className={style.ticket_type_item}>
              {ticketType.value !== "my_tickets" && (
                <MemberComboBox
                  className={mergeClasses(style.selector, style.search_input)}
                  members={membersList}
                  selectedUsers={selectedUserOptions}
                  onSubmit={(selectedusers) =>
                    setSelectedUserOptions(selectedusers)
                  }
                />
              )}
            </div>
          </>
        </div>
      )}
      <div id="home_body_div" className={style.home_body_div}>
        {isMobile && (
          <Input
            className={mergeClasses(
              style.search_input,
              style.mobile_search_bar
            )}
            appearance="filled-lighter"
            placeholder="Search"
            value={searchValue}
            onChange={(_, value) => setSearchValue(value.value)}
            contentAfter={<Search20Regular />}
          />
        )}
        {/* ticket status */}
        <div className={style.status_conatiner_box}>
          {(Array.isArray(ticketStatus) ? ticketStatus : []).map(
            (statusItem) => (
              <div
                key={statusItem?.status_id}
                className={mergeClasses(
                  style.status_conatiner_box_div,
                  themeString === "dark" && style.status_conatiner_box_div_dark
                )}
              >
                <Body1Stronger>{`${statusItem?.status_name} ${
                  statusItem?.status_id !== "null" ? "Tickets" : ""
                }`}</Body1Stronger>
                <Subtitle1 className={style.status_conatiner_box_div_count}>
                  {statusItem?.count || 0}
                </Subtitle1>
              </div>
            )
          )}
        </div>
        {/* ticket status end*/}

        <div id="filter_table_body" className={style.filter_table_body}>
          {/* filter bar */}
          <div className={style.table_action_bar}>
            {ticketView === "list" ? (
              <Subtitle1>Tickets</Subtitle1>
            ) : (
              !isMiniDevice && (
                <div>
                  <Checkbox
                    label={isMobile ? "Select All" : "Select All Tickets"}
                    checked={isAllSelected}
                    onChange={() => {
                      if (isAllSelected) {
                        return setSelectedTickets([]);
                      }
                      setSelectedTickets(
                        ticketData?.result.filter(
                          (item: any) =>
                            !DISABLED_STATUS.includes(item?.ticket_status)
                        ) || []
                      );
                    }}
                  />
                </div>
              )
            )}
            <div
              className={mergeClasses(
                style.table_action_bar_div,
                isMiniDevice && style.seperator
              )}
            >
              {!isMobile && (
                <>
                  <ToggleButton
                    appearance="transparent"
                    checked={ticketView === "list"}
                    icon={<List20Regular />}
                    onClick={() => setTicketView("list")}
                  />
                  <ToggleButton
                    appearance="transparent"
                    checked={ticketView === "grid"}
                    icon={
                      ticketView === "grid" ? (
                        <Grid20Filled />
                      ) : (
                        <Grid20Regular />
                      )
                    }
                    onClick={() => setTicketView("grid")}
                  />
                </>
              )}
              {!isMobile && (
                <Input
                  className={style.search_input}
                  appearance="filled-lighter"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(_, value) => setSearchValue(value.value)}
                  contentAfter={<Search20Regular />}
                />
              )}
              <FilterComponent />
              <Button
                disabled={selectedTickets.length <= 0}
                onClick={() => setIsCancelTicketModalOpen(true)}
              >
                Cancel Tickets
              </Button>
            </div>
          </div>
          {/* filter bar end */}
          {isMiniDevice && (
            <div
              style={{
                paddingBottom: "16px",
              }}
            >
              <Checkbox
                label={isMobile ? "Select All" : "Select All Tickets"}
                checked={isAllSelected}
                onChange={() => {
                  if (isAllSelected) {
                    return setSelectedTickets([]);
                  }
                  setSelectedTickets(
                    ticketData?.result.filter(
                      (item: any) =>
                        !DISABLED_STATUS.includes(item?.ticket_status)
                    ) || []
                  );
                }}
              />
            </div>
          )}
          <div className={style.ticket_list}>
            <TicketListViewer
              isLoading={isTicketLoading}
              ticketList={ticketData?.result || []}
              view={isMobile ? "grid" : ticketView}
              isAllSelected={isAllSelected}
              isScrollable={isScrollable}
              onItemSelected={(selectedItems) => {
                setSelectedTickets(selectedItems);
              }}
              selectedItems={selectedTickets}
              isMemberTickets={
                isPrivilaged && ticketType.value !== "my_tickets"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  home_body: {
    width: "100vw",
    height: "100%",
    display: "flex",
    ...shorthands.overflow("hidden", "auto"),
    flexDirection: "column",
    ...shorthands.padding("16px", "16px", "0px", "16px"),
  },
  home_header: {
    display: "inline-flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "sticky",
    top: "0px",
    left: "0px",
    right: "0px",
    zIndex: "3",
    paddingBottom: "16px",
  },
  home_body_div: {
    width: "100%",
    overflowY: "scroll",
    display: "block",
    ...shorthands.gap("32px"),
    ...shorthands.flex(1),
    "&::-webkit-scrollbar": {
      width: "none",
      backgroundColor: "transparent",
      display: "none",
    },
  },
  status_conatiner_box: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    minHeight: "fit-content",
    paddingBottom: "4px",
    ...shorthands.overflow("auto", "hidden"),
    ...shorthands.gap("16px"),

    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },

    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: tokens.colorNeutralBackground1Hover,
      ...shorthands.borderRadius("6px"),
    },

    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: tokens.colorNeutralBackground1Pressed,
    },
  },
  status_conatiner_box_div: {
    flexGrow: "1",
    minWidth: "max(15%, 250px)",
    backgroundColor: "#f5f5f5",
    display: "flex",
    height: "fit-content",
    position: "relative",
    flexDirection: "column",
    ...shorthands.gap("16px"),
    ...shorthands.borderRadius("4px"),
    ...shorthands.padding("20px"),
  },
  seperator: {
    justifyContent: "space-between",
    ...shorthands.flex(1),
  },
  status_conatiner_box_div_count: { fontWeight: "400" },
  status_conatiner_box_div_dark: { color: "400" },

  filter_table_body: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  ticket_list: {
    width: "100%",
    paddingBottom: "16px",
    ...shorthands.overflow("hidden"),
    ...shorthands.flex(1),
    display: "flex",
  },

  table_action_bar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    ...shorthands.gap("16px"),
    ...shorthands.padding("24px", "0px"),
  },

  table_action_bar_div: {
    display: "flex",
    flexDirection: "row",
    width: "fit-content",
    ...shorthands.gap("16px"),
  },
  search_input: { backgroundColor: "#dcdcdc58 !important" },
  mobile_search_bar: {
    marginBottom: "16px",
    width: "100%",
  },
  header_action: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("8px", "16px"),
  },
  dropdown: {
    minWidth: "130px",
  },
  ticket_type_box: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("16px"),
    ...shorthands.padding("16px", "0px"),
  },
  ticket_type_item: {
    ...shorthands.flex(1),
  },
  selector: {
    minWidth: "100%",
  },
});

const superMangerTicketTypes = [
  {
    label: "All tickets",
    value: "all_tickets",
  },
  {
    label: "My tickets",
    value: "my_tickets",
  },
  {
    label: "Team tickets",
    value: "team_tickets",
  },
];

const privillagedUserTicketTypes = [
  {
    label: "My tickets",
    value: "my_tickets",
  },
  {
    label: "Team tickets",
    value: "team_tickets",
  },
];
