import { Text, tokens } from "@fluentui/react-components";
import { Box } from "components/Box";
import React from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";

interface AttachmentDnDProps {
  onDrop: (attachmentFiles: any) => void;
  sx?: React.CSSProperties | undefined;
}

const sizeValidator = (file: any) => {
  if (file.size > 10485760) {
    toast.error("File size larger than 10MB");
    return {
      code: "size-too-large",
      message: "Size is larger than expected",
    };
  }

  return null;
};
export const AttachmentDragAndDrop = ({ onDrop, sx }: AttachmentDnDProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: (fileRejections: any) => {},
    // accept: {
    //   "image/*": [".png", ".jpe", ".jpeg", ".jpg"],
    //   "application/pdf": [".pdf", ".csv"],
    //   "application/msword": [".doc"],
    //   "application/vnd.ms-excel": [".xls", ".xlt", ".xla"],
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    //     ".xlsx",
    //   ],
    //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    //     [".docs"],
    // },
    validator: sizeValidator,
  });

  return (
    <div
      {...getRootProps()}
      style={{
        cursor: "pointer",
        // TODO: make theme color
        backgroundColor: tokens.colorBrandBackgroundInvertedPressed,
        height: "64px",
        borderRadius: "8px",
        border: `1px dashed black`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: tokens.colorNeutralBackground1,
        ...sx,
      }}
    >
      <input {...getInputProps()} accept="image/*" />
      <Text
        as="h6"
        style={{
          color: tokens.colorNeutralForeground1Static,
        }}
      >
        Drag & drop files here or{" "}
        <Text as="span" style={{ textDecoration: "underline" }}>
          Browse
        </Text>
      </Text>
    </div>
  );
};
