import { immer } from "zustand/middleware/immer";
interface GlobalState {
  customerId: string;
  userId: string;
  token: string;
  displayName: string;
  profilePicture: string;
  isPrivilaged: boolean;
  msId: string;
  email: string;
  isPermissionAllowed: boolean;
  isSuperManager: boolean;
}

interface GlobalAction {
  setToken: (token: string) => void;
  setCredentialData: (data: {
    customerId: string;
    userId: string;
    token: string;
    isPermissionAllowed: boolean;
  }) => void;
  setProfileData: (data: {
    displayName: string;
    profilePicture: string;
    isPrivilaged: boolean;
    email: string;
    msId: string;
    isSuperManager: boolean;
    isPermissionAllowed: boolean;
  }) => void;
  resetProfileData: () => void;
}

export interface GlobalInterface extends GlobalState, GlobalAction {}

const initialData = {
  customerId: "",
  userId: "",
  token: "",
  displayName: "",
  profilePicture: "",
  isPrivilaged: false,
  msId: "",
  email: "",
  isPermissionAllowed: false,
  isSuperManager: false,
};

export const globalSlice = immer<GlobalState & GlobalAction>((set, get) => ({
  ...initialData,
  setToken(token) {
    set((state) => {
      state.token = token;
    });
  },
  setCredentialData: (data) => {
    set((state) => {
      state.customerId = data.customerId;
      state.userId = data.userId;
      state.token = data.token;
      state.isPermissionAllowed = data.isPermissionAllowed;
    });
  },

  setProfileData: (data) => {
    set((state) => {
      state.displayName = data.displayName;
      state.profilePicture = data.profilePicture;
      state.isPrivilaged = data.isPrivilaged;
      state.email = data.email;
      state.msId = data.msId;
      state.isPermissionAllowed = data.isPermissionAllowed;
      state.isSuperManager = data.isSuperManager;
    });
  },
  resetProfileData: () => {
    set((state) => initialData);
  },
}));
