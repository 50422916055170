/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Caption1,
  Caption1Strong,
  Divider,
  makeStyles,
  mergeClasses,
  shorthands,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import {
  MqttContext,
  useGetAllChatMessages,
  useGlobalStore,
  useSendChatMessage,
} from "controller";
import dayjs from "dayjs";
import { useContext, useEffect, useRef, useState } from "react";
import { CLOSED_TICKET, ChatResultItem, MERGED_TICKET } from "shared";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import relativeTime from "dayjs/plugin/relativeTime";
import { Send20Regular, Attach20Regular } from "@fluentui/react-icons";
import { ChatBubble } from "../ChatBubble";
import { ChatAttachmentModal } from "components/Modal/attachment-modal/ChatAttachmentModal";
import { RatingBox } from "components";
import NoChatImg from "assets/images/noChat.png";
import { ChatMessageInput } from "./ChatMessageInput";
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

export const TicketChat = ({
  serviceId,
  ticketDetails,
  isDisabled = false,
}: {
  serviceId: string;
  ticketDetails: any;
  isDisabled?: boolean;
}) => {
  const quillRef = useRef(null);
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const { mqttMessage } = useContext(MqttContext);
  const style = useStyle();
  const [textFous, setTextFous] = useState(false);
  const [lastUpdated, setLastUpdated] = useState<string | undefined>();
  const customerUserId = useGlobalStore((state) => state.userId);
  const [customerUsers, setCustomerUsers] = useState<Array<any>>([]);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);

  const { mutateAsync: sendChatMessage } = useSendChatMessage();
  const [message, setMessage] = useState("");
  const {
    data: prevChatMessages,
    isLoading: isMessagesLoading,
    isFetching: isMessageFetching,
  } = useGetAllChatMessages({
    serviceId: serviceId,
    serviceType: "tickets",
  });
  const [groupedMessages, setGroupedMessages] = useState<Record<
    string,
    ChatResultItem[]
  > | null>();

  useEffect(() => {
    if (prevChatMessages && !isMessagesLoading && !isMessageFetching) {
      setGroupedMessages(undefined);
      const cutomerUserList = Array.isArray(prevChatMessages?.customer_users)
        ? prevChatMessages?.customer_users
            .filter((item: any) => item.mapping_id === customerUserId)
            .map((item: any) => ({
              mapping_id: item?.mapping_id,
              display_name: item?.display_name,
              profile_pic: item?.profile_pic,
            }))
        : [];
      setCustomerUsers([...cutomerUserList]);
      transformMessages({
        inputMessage: prevChatMessages?.messages,
        type: "initial",
      });
    }

    return () => {
      setGroupedMessages(null);
    };
  }, [prevChatMessages, isMessagesLoading, isMessageFetching]);

  useEffect(() => {
    console.log("🚀 ~ customerUsers:", customerUsers);
  }, [customerUsers]);

  useEffect(() => {
    if (mqttMessage == null) {
    } else {
      if (mqttMessage.topic === `tickets/${serviceId}`) {
        const newMessage = JSON.parse(mqttMessage?.message);

        addMessager(newMessage?.savedChat?.sender);

        transformMessages({
          inputMessage: [newMessage?.savedChat],
          type: "new",
        });
      }
    }
  }, [mqttMessage]);

  const addMessager = async (sender: any) => {
    if (sender) {
      const result = customerUsers.find(
        (item) => item.mapping_id === sender?.mapping_id
      );
      if (!result) {
        setCustomerUsers([
          ...customerUsers,
          {
            mapping_id: sender?.mapping_id,
            display_name: sender?.display_name,
            profile_pic: sender?.profile_pic,
          },
        ]);
      }
    }
  };

  const transformMessages = ({
    inputMessage,
    type,
  }: {
    inputMessage: ChatResultItem[];
    type: "initial" | "new";
  }) => {
    const lastItem = inputMessage[inputMessage.length - 1];
    lastItem && setLastUpdated(lastItem?.created_at);
    if (inputMessage && type === "initial") {
      const groupedMessages = inputMessage.reduce(
        (groups: Record<string, Array<ChatResultItem>>, message) => {
          // @ts-ignore
          const date: string = message.created_at;
          let key = date;

          if (dayjs(date).isToday()) {
            key = "Today";
          } else if (dayjs(date).isYesterday()) {
            key = "Yesterday";
          } else {
            key = dayjs(date).format("DD MMM YYYY");
          }
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].unshift(message);
          return groups;
        },
        {}
      );
      setGroupedMessages(groupedMessages);
    } else {
      setGroupedMessages((prev) => {
        const newData = Object.assign({}, prev);
        const hasToday = newData.hasOwnProperty("Today");
        const today = [...(hasToday ? newData["Today"] : []), ...inputMessage];
        delete newData["Today"];
        return {
          Today: today,
          ...newData,
        };
      });
    }
    // scrollToBottom();
  };

  const sendChatMessageHandler = async (
    message: string,
    attachments: Array<any>
  ) => {
    setMessage("");
    setIsSendingMessage(true);
    try {
      if (attachments.length > 0) {
        await sendChatMessage({
          serviceType: "ticket",
          serviceId: serviceId,
          message: message,
          attachments: attachments
            .filter((item: any) => item?.status === "Uploaded")
            .map((item: any) => item?.responseData),
        });
        return;
      }
      if (message.trim().length > 0) {
        await sendChatMessage({
          serviceType: "ticket",
          serviceId: serviceId,
          message: message,
          attachments: [],
        });
        if (quillRef) {
          // @ts-ignore
          quillRef.current.clear();
        }
      }
    } catch (e) {}
    setIsSendingMessage(false);
  };

  return (
    <div className={style.root}>
      {isAttachmentOpen && (
        <ChatAttachmentModal
          open={isAttachmentOpen}
          onClose={() => {
            setIsAttachmentOpen(false);
          }}
          onSubmit={(data) => {
            sendChatMessageHandler(data.message || "", data.attachment);
            setIsAttachmentOpen(false);
          }}
        />
      )}
      <div className={style.chatContainer}>
        {!isMessagesLoading && (
          <>
            {ticketDetails?.ticket_status === CLOSED_TICKET ? (
              <RatingBox
                rating={ticketDetails?.rating}
                serviceId={serviceId}
                message={ticketDetails?.feedback}
              />
            ) : ticketDetails?.ticket_status === MERGED_TICKET ? (
              <div className={style.merged_ticket}>
                <Caption1>Chat disabled: ticket has been merged.</Caption1>
              </div>
            ) : null}

            {groupedMessages && Object.keys(groupedMessages).length > 0
              ? Object.entries(groupedMessages).map(([date, msgs]) => (
                  <div key={date} className={style.chatGroupContainer}>
                    <Divider>
                      {date !== "Today" && date !== "Yesterday"
                        ? dayjs(date).format("DD MMM YYYY")
                        : date}
                    </Divider>
                    {msgs.map((message) => (
                      <ChatBubble
                        key={message.chat_id}
                        messageDetails={message}
                      />
                    ))}
                  </div>
                ))
              : ticketDetails?.ticket_status !== CLOSED_TICKET && (
                  <div className={style.noMessaageBox}>
                    <img
                      className={"noChatImg"}
                      src={NoChatImg}
                      alt="rating icon"
                    />
                    <Caption1>
                      You’re about to start a new conversation{" "}
                    </Caption1>
                  </div>
                )}
          </>
        )}
      </div>
      <div className={style.inputBox}>
        {isUploadingImages && (
          <div className={style.image_upload_progress_container}>
            <Spinner size="extra-tiny" />
            <Caption1Strong
              style={{
                color: tokens.colorNeutralForeground1Static,
              }}
            >
              Uploading images...
            </Caption1Strong>
          </div>
        )}
        <div
          className={mergeClasses(
            style.input,
            textFous && !isDisabled && style.input_focus,
            isDisabled && style.input_disabled
          )}
        >
          <Button
            disabled={isDisabled}
            appearance="transparent"
            onClick={() => setIsAttachmentOpen(true)}
            icon={<Attach20Regular />}
          />
          <div style={{ maxHeight: "120px", overflowY: "auto", flex: "1" }}>
            <ChatMessageInput
              serviceId={serviceId}
              serviceType={"ticket"}
              ref={quillRef}
              onSend={() => {
                !(isDisabled || isSendingMessage || isUploadingImages) &&
                  sendChatMessageHandler(message, []);
              }}
              isDisabled={isDisabled}
              onChange={(e) => {
                setMessage(e || "");
              }}
              value={message}
              toggleUploadingStatus={(value) => {
                setIsUploadingImages(value);
              }}
            />
            {/* <TextField
              disabled={isDisabled}
              value={message}
              placeholder="Type a new message"
              onChange={(_, data) => setMessage(data || "")}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendChatMessageHandler(message, []);
                  e.preventDefault();
                }
              }}
              multiline
              autoAdjustHeight
              borderless
              rows={1}
              onFocus={() => setTextFous(true)}
              onBlur={() => setTextFous(false)}
              resizable={false}
              styles={{
                field: {
                  flexGrow: 1,
                  backgroundColor: "transparent !important",
                  color: tokens.colorNeutralForeground1,
                },
                root: {
                  width: "100%",
                  overflow: "hidden",
                },
                fieldGroup: {
                  height: "100%",
                  overflow: "auto",
                  minHeight: "32px",
                  position: "inherit",
                  backgroundColor: "transparent !important",
                },
              }}
            /> */}
          </div>
          <Button
            disabled={isDisabled || isSendingMessage || isUploadingImages}
            appearance="transparent"
            onClick={() => sendChatMessageHandler(message, [])}
            icon={<Send20Regular />}
          />
        </div>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.padding("0px", "20px", "8px", "20px"),
    ...shorthands.gap("16px"),
    ...shorthands.flex(1),
  },
  chatContainer: {
    width: "100%",
    display: "flex",
    height: "300px",
    flexDirection: "column-reverse",
    overflowY: "auto",
    ...shorthands.flex(1),
    ...shorthands.padding("16px", "0px", "0px", "0px"),
  },
  noMessaageBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .noChatImg": {
      width: "min(100%, 250px)",
      aspectRatio: 1,
      objectFit: "contain",
    },
  },
  chatGroupContainer: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
  },

  image_upload_progress_container: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    zIndex: 1,
    width: "100%",
    alignItems: "center",

    backgroundColor: "#E7E9FE",
    top: "-26px",
    boxShadow:
      "0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)",
    ...shorthands.gap("8px"),
    ...shorthands.borderRadius("8px 8px 0px 0px"),
    ...shorthands.padding("4px", "8px"),
  },

  inputBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    ...shorthands.gap("16px", "4px"),
    position: "relative",
  },
  input: {
    ...shorthands.flex(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    ...shorthands.gap("8px"),
    ...shorthands.padding("2px", "4px", "4px", "4px"),
    ...shorthands.borderRadius("4px"),
    ...shorthands.border("1px", "solid", "grey"),
  },
  input_focus: {
    ...shorthands.borderBottom("1px", "solid", "#5B5FC7"),
  },
  input_disabled: {
    ...shorthands.border("1px", "solid", tokens.colorNeutralForegroundDisabled),
  },
  merged_ticket: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.padding("32px", "0px", "16px", "0px"),
  },
});
