export const getFileTypeFromUrl = (url: string): string | null => {
  const fileExtension = url.substring(url.lastIndexOf(".") + 1);
  const validExtensions = [
    "xlsx",
    "xls",
    "csv",
    "pdf",
    "docx",
    "doc",
    "txt",
    "png",
    "jpg",
    "jpeg",
    "gif",
    "bmp",
  ]; // Add more if needed

  //   if (validExtensions.includes(fileExtension.toLowerCase())) {
  return fileExtension.toUpperCase();
  //   } else {
  //     return null; // Invalid file type
  //   }
};

const mimeTypeToExtension: { [key: string]: string } = {
  "image/jpeg": ".jpg",
  "image/jpg": ".jpg",
  "image/png": ".png",
  "image/gif": ".gif",
  "image/webp": ".webp",
  "audio/mpeg": ".mp3",
  "audio/wav": ".wav",
  "video/mp4": ".mp4",
  "application/pdf": ".pdf",
  "application/zip": ".zip",
  "text/plain": ".txt",
  "application/json": ".json",
  "application/msword": ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
};

export const getFileExtensionFromMimeType = (mimeType: string) => {
  return mimeTypeToExtension[mimeType] || "";
};
